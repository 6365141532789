<template>
    <div class="container mt-5">
        <div class="py-32pt navbar-submenu">
            <div class="container page__container">
                <div class="progression-bar progression-bar--active-primary">
                    <div class="progression-bar__item"
                         :class="{'progression-bar__item--complete progression-bar__item--complete': progress > 0,
                                    'progression-bar__item--active': progress === 0
                            }"
                    >
                                <span class="progression-bar__item-content">
                                    <i class="material-icons progression-bar__item-icon" v-if="progress > 0">done</i>
                                    <i class="material-icons progression-bar__item-icon" v-else></i>
                                    <span class="progression-bar__item-text h5 mb-0 text-uppercase">Plano</span>
                                </span>
                    </div>
                    <div
                        class="progression-bar__item"
                        :class="{'progression-bar__item--complete': progress > 1,
                                    'progression-bar__item--active progression-bar__item--complete': progress === 1
                            }"
                    >
                                <span class="progression-bar__item-content">
                                    <i class="material-icons progression-bar__item-icon" v-if="progress > 1">done</i>
                                    <i class="material-icons progression-bar__item-icon" v-else></i>
                                    <span
                                        class="progression-bar__item-text h5 mb-0 text-uppercase">Dados Pessoais</span>
                                </span>
                    </div>
                    <div class="progression-bar__item"
                         :class="{'progression-bar__item--active progression-bar__item--complete': progress === 2}"
                    >
                                <span class="progression-bar__item-content">
                                    <i class="material-icons progression-bar__item-icon"></i>
                                    <span
                                        class="progression-bar__item-text h5 mb-0 text-uppercase">Detalhes do Pagamento</span>
                                </span>
                    </div>
                </div>
            </div>
        </div>


        <div class="navbar-submenu py-32pt navbar-shadow mt-5" v-if="finished">
            <div class="col text-center my-5 f-100">
                <i class="fa fa-check-circle text-success" style="font-size: 40px"></i>
            </div>
            <div class="col text-center mb-5">
                <h3>Cadastro realizado com sucesso!</h3>
                <p>Você irá receber em seu e-mail de cadastro um link para cadastrar sua senha.</p>
            </div>
        </div>


        <div class="page-section container page__container" v-else>

            <!--            Step Planos-->

            <div class="container page__container" v-show="progress === 0">
                <div class="row card-group-row mb-lg-40pt">
                    <div class="col-lg-4 col-sm-6 card-group-row__col pointer">

                        <div class="card card-group-row__card text-center o-hidden card--raised position-relative"
                             :class="{'border-primary': form.cycle === 'MONTHLY',
                                        'border-0': form.cycle !== 'MONTHLY',
                                }"
                             @click="form.cycle = 'MONTHLY'; form.plan = 1"
                        >
                            <i class="fa fa-check-circle position-absolute text-primary"
                               v-if="form.cycle === 'MONTHLY'"
                               style="right: 10px; top: 10px"></i>

                            <!--                                <span-->
                            <!--                                    class="corner-ribbon corner-ribbon&#45;&#45;default-right-top corner-ribbon&#45;&#45;shadow bg-primary text-white">Start</span>-->

                            <div class="card-body d-flex flex-column">
                                <div class="flex-grow mb-16pt">
                                            <span
                                                class="w-64 h-64 icon-holder rounded-circle d-inline-flex mb-16pt"
                                                :class="{
                                                    'icon-holder--outline-primary text-primary': form.cycle === 'MONTHLY',
                                                    'icon-holder--outline-secondary': form.cycle !== 'MONTHLY',
                                                }"
                                            >
                                                <i class="material-icons">person</i>
                                            </span>
                                    <h4 class="mb-8pt">Mensal</h4>
                                    <p class="text-70 mb-0">Access to over 1.000 high quality courses. For
                                        individuals.</p>
                                </div>
                                <p class="d-flex justify-content-center align-items-center m-0">
                                    <span class="h4 m-0 font-weight-normal">R$</span>
                                    <span class="h1 m-0 font-weight-normal">200</span>
                                    <span class="h4 m-0 font-weight-normal">/ mês</span>
                                </p>
                            </div>
                        </div>

                    </div>
                    <div class="col-lg-4 col-sm-6 card-group-row__col">

                        <div class="card card-group-row__card text-center o-hidden pointer"
                             :class="{'border-primary': form.cycle === 'SEMIANNUALLY',
                                        'border-0': form.cycle !== 'SEMIANNUALLY',
                                }"
                             @click="form.cycle = 'SEMIANNUALLY'; form.plan = 2"
                        >

                            <i class="fa fa-check-circle position-absolute text-primary"
                               v-if="form.cycle === 'SEMIANNUALLY'"
                               style="right: 10px; top: 10px"></i>

                            <div class="card-body d-flex flex-column">
                                <div class="flex-grow mb-16pt">
                                            <span
                                                class="w-64 h-64 icon-holder rounded-circle d-inline-flex mb-16pt"
                                                :class="{
                                                    'icon-holder--outline-primary text-primary': form.cycle === 'SEMIANNUALLY',
                                                    'icon-holder--outline-secondary': form.cycle !== 'SEMIANNUALLY',
                                                }"
                                            >
                                                <i class="material-icons">group</i>
                                            </span>
                                    <h4 class="mb-8pt">Semestral</h4>
                                    <p class="text-70 mb-0">Starts with 3 accounts with more seats available.</p>
                                </div>
                                <p class="d-flex justify-content-center align-items-center m-0">
                                    <span class="h4 m-0 font-weight-normal">R$</span>
                                    <span class="h1 m-0 font-weight-normal">180</span>
                                    <span class="h4 m-0 font-weight-normal">/ mês</span>
                                </p>

                            </div>
                        </div>

                    </div>
                    <div class="col-lg-4 col-sm-6 card-group-row__col">

                        <div class="card card-group-row__card text-center o-hidden pointer"
                             :class="{'border-primary': form.cycle === 'YEARLY',
                                        'border-0': form.cycle !== 'YEARLY',
                                }"
                             @click="form.cycle = 'YEARLY'; form.plan = 3"
                        >
                            <i class="fa fa-check-circle position-absolute text-primary"
                               v-if="form.cycle === 'YEARLY'"
                               style="right: 10px; top: 10px"></i>

                            <div class="card-body d-flex flex-column">
                                <div class="flex-grow mb-16pt">
                                            <span
                                                class="w-64 h-64 icon-holder rounded-circle d-inline-flex mb-16pt"
                                                :class="{
                                                    'icon-holder--outline-primary text-primary': form.cycle === 'YEARLY',
                                                    'icon-holder--outline-secondary': form.cycle !== 'YEARLY',
                                                }"
                                            >
                                                <i class="material-icons">business_center</i>
                                            </span>
                                    <h4 class="mb-8pt">Anual</h4>
                                    <p class="text-70 mb-0">Build customized learning paths with content that aligns
                                        to your internal learning goals.</p>
                                </div>
                                <p class="d-flex justify-content-center align-items-center m-0">
                                    <span class="h4 m-0 font-weight-normal">R$</span>
                                    <span class="h1 m-0 font-weight-normal">150</span>
                                    <span class="h4 m-0 font-weight-normal">/ mês</span>
                                </p>

                            </div>
                        </div>

                    </div>
                </div>

                <!--                    <div class="page-headline page-headline&#45;&#45;white page-headline&#45;&#45;title text-center">-->
                <!--                        <h2 class="">All plans include</h2>-->
                <!--                    </div>-->

                <!--                    <div class="col-lg-8 mx-auto">-->
                <!--                        <div class="row">-->
                <!--                            <div class="col-sm-6 mb-24pt mb-sm-0">-->
                <!--                                <ul class="list-unstyled">-->
                <!--                                    <li class="d-flex align-items-center mb-8pt">-->
                <!--                                        <span class="-20 material-icons mr-8pt">check</span>-->
                <!--                                        <span class="-70">24h Access to PRO Courses</span>-->
                <!--                                    </li>-->
                <!--                                    <li class="d-flex align-items-center mb-8pt">-->
                <!--                                        <span class="-20 material-icons mr-8pt">check</span>-->
                <!--                                        <span class="-70">Join 2000+ Community Members</span>-->
                <!--                                    </li>-->
                <!--                                    <li class="d-flex align-items-center mb-8pt">-->
                <!--                                        <span class="-20 material-icons mr-8pt">check</span>-->
                <!--                                        <span class="-70">Access to New Courses Weekly</span>-->
                <!--                                    </li>-->
                <!--                                    <li class="d-flex align-items-center mb-8pt">-->
                <!--                                        <span class="-20 material-icons mr-8pt">check</span>-->
                <!--                                        <span class="-70">Support from our Tutors</span>-->
                <!--                                    </li>-->
                <!--                                    <li class="d-flex align-items-center">-->
                <!--                                        <span class="-20 material-icons mr-8pt">check</span>-->
                <!--                                        <span class="-70">Assets Included per Course</span>-->
                <!--                                    </li>-->
                <!--                                </ul>-->
                <!--                            </div>-->
                <!--                            <div class="col-sm-6">-->
                <!--                                <ul class="list-unstyled">-->
                <!--                                    <li class="d-flex align-items-center mb-8pt">-->
                <!--                                        <span class="-20 material-icons mr-8pt">check</span>-->
                <!--                                        <span class="-70">24h Access to PRO Courses</span>-->
                <!--                                    </li>-->
                <!--                                    <li class="d-flex align-items-center mb-8pt">-->
                <!--                                        <span class="-20 material-icons mr-8pt">check</span>-->
                <!--                                        <span class="-70">Join 2000+ Community Members</span>-->
                <!--                                    </li>-->
                <!--                                    <li class="d-flex align-items-center mb-8pt">-->
                <!--                                        <span class="-20 material-icons mr-8pt">check</span>-->
                <!--                                        <span class="-70">Access to New Courses Weekly</span>-->
                <!--                                    </li>-->
                <!--                                    <li class="d-flex align-items-center mb-8pt">-->
                <!--                                        <span class="-20 material-icons mr-8pt">check</span>-->
                <!--                                        <span class="-70">Support from our Tutors</span>-->
                <!--                                    </li>-->
                <!--                                    <li class="d-flex align-items-center">-->
                <!--                                        <span class="-20 material-icons mr-8pt">check</span>-->
                <!--                                        <span class="-70">Assets Included per Course</span>-->
                <!--                                    </li>-->
                <!--                                </ul>-->
                <!--                            </div>-->
                <!--                        </div>-->
                <!--                    </div>-->

            </div>

            <!--            Step dados pessoais-->
            <div class="col-lg-10 p-0 mx-auto" v-show="progress === 1">
                <div class="row">
                    <div class="col-md-6 mb-3">
                        <label class="form-label" for="name">Nome Completo:</label>
                        <input id="name" type="text" class="form-control" :disabled="disabled" v-model="form.name"
                               placeholder="Nome completo ...">
                    </div>
                    <div class="col-md-6 mb-3">
                        <label class="form-label" for="email">E-mail:</label>
                        <input id="email" type="email" class="form-control" :disabled="disabled" v-model="form.email"
                               placeholder="seu_email@email.com">
                    </div>
                    <div class="col-md-4 mb-3">
                        <label class="form-label" for="mobilePhone">Telefone:</label>
                        <input id="mobilePhone" type="text" class="form-control" v-model="form.mobilePhone"
                               placeholder="(__) _____-____" v-maska="['(##) ####-####', '(##) #####-####']">
                    </div>

                    <div class="col-md-4 mb-3">
                        <label class="form-label" for="cpfCnpj">CPF/CNPJ:</label>
                        <input id="cpfCnpj" type="text" class="form-control" v-model="form.cpfCnpj"
                               placeholder="CPF/CNPJ" v-maska="['###.###.###-##', '##.###.###/####-##']">
                    </div>

                    <!--                    <div class="col-md-6 mb-3">-->
                    <!--                        <label class="form-label" for="birthDate">Data de Nascimento:</label>-->
                    <!--                        <input id="birthDate" type="date" class="form-control" v-model="form.birthDate"-->
                    <!--                               placeholder="">-->
                    <!--                    </div>-->

                    <div class="col-md-4 mb-3">
                        <label class="form-label" for="postalCode">CEP:</label>
                        <input id="postalCode" type="text" class="form-control" v-model="form.postalCode"
                               placeholder="CEP" v-maska="'##.###-###'" @keyup="setAddress">
                    </div>

                    <div class="col-md-1 mb-3">
                        <label class="form-label" for="uf">UF:</label>
                        <input id="uf" type="text" class="form-control" v-model="form.uf"
                               placeholder="UF">
                    </div>

                    <div class="col-md-3 mb-3">
                        <label class="form-label" for="city">Cidade:</label>
                        <input id="city" type="text" class="form-control" v-model="form.city"
                               placeholder="Cidade">
                    </div>

                    <div class="col-md-4 mb-3">
                        <label class="form-label" for="address">Endereço:</label>
                        <input id="address" type="text" class="form-control" v-model="form.address"
                               placeholder="Endereço">
                    </div>

                    <div class="col-md-4 mb-3">
                        <label class="form-label" for="province">Bairro:</label>
                        <input id="province" type="text" class="form-control" v-model="form.province"
                               placeholder="Bairro">
                    </div>

                    <div class="col-md-5 mb-3">
                        <label class="form-label" for="complement">Complemento:</label>
                        <input id="complement" type="text" class="form-control" v-model="form.complement"
                               placeholder="Complemento">
                    </div>

                    <div class="col-md-2 mb-3">
                        <label class="form-label" for="addressNumber">Número:</label>
                        <input id="addressNumber" type="text" class="form-control" v-model="form.addressNumber"
                               placeholder="Número">
                    </div>

                    <!--                    <div class="col-md-6 mb-3">-->
                    <!--                        <label class="form-label" for="incomeValue">Renda Mensal:</label>-->
                    <!--                        <input id="incomeValue" type="text" class="form-control" v-model="form.incomeValue"-->
                    <!--                               placeholder="Renda Mensal">-->
                    <!--                    </div>-->

                </div>
            </div>

            <!--            Step de Pagamento-->
            <div class="col-lg-10 p-0 mx-auto" v-show="progress === 2">
                <div class="row">
                    <div class="col-md-6">
                        <h3>Informações do Cartão de Crédito</h3>
                        <div class="row">

                            <div class="mb-3 col-12">
                                <label class="form-label" for="holderName">Nome do Titular do Cartão:</label>
                                <input id="holderName" type="text" class="form-control" v-model="form.holderName"
                                       placeholder="Nome do Titular do Cartão">
                            </div>

                            <div class="mb-3 col-12">
                                <label class="form-label" for="number">Número do Cartão:</label>
                                <input id="number" type="text" class="form-control" v-model="form.number"
                                       placeholder="Nome do Titular do Cartão" v-maska="'#### #### #### ####'">
                            </div>

                            <div class="mb-3 col-md-6">
                                <label class="form-label" for="expiration">Expiração:</label>
                                <input id="expiration" type="text" class="form-control" v-model="form.expiration"
                                       placeholder="mm/AAAA" v-maska="'##/####'">
                            </div>

                            <div class="mb-3 col-md-6">
                                <label class="form-label" for="ccv">CCV:</label>
                                <input id="ccv" type="text" class="form-control" v-model="form.ccv"
                                       placeholder="CCV" v-maska="'###'">
                            </div>

                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="card mb-0">
                            <div class="card-body">
                                <h5>Resumo da compra</h5>
                                <div class="d-flex mb-8pt">
                                    <div class="flex"><strong class="text-70">Plano</strong></div>
                                    <strong v-if="form.cycle === 'MONTHLY'">Mensal</strong>
                                    <strong v-if="form.cycle === 'SEMIANNUALLY'">Semestral</strong>
                                    <strong v-if="form.cycle === 'YEARLY'">Anual</strong>
                                </div>

                                <div class="alert alert-soft-warning">
                                    <div class="d-flex flex-wrap align-items-start">
                                        <div class="mr-8pt">
                                            <i class="material-icons">check</i>
                                        </div>
                                        <div class="flex" style="min-width: 180px">
                                            <small class="text-100">
                                                Acesso a mais de 1.000 cursos de alta qualidade. Para indivíduos.
                                            </small>
                                        </div>
                                    </div>
                                </div>

                                <div class="d-flex mb-16pt pb-16pt border-bottom">
                                    <div class="flex"><strong class="text-70">Valor</strong></div>
                                    <strong v-if="form.cycle === 'MONTHLY'">R$ 200,00 por mês</strong>
                                    <strong v-if="form.cycle === 'SEMIANNUALLY'">R$ 180,00 por mês</strong>
                                    <strong v-if="form.cycle === 'YEARLY'">R$ 150,00 por mês</strong>
                                </div>
                                <div class="custom-control custom-checkbox">
                                    <input type="checkbox" class="mr-1 mb-0" checked id="topic-all">
                                    <label>Termos e condições</label>
                                    <small class="form-text text-muted">Ao marcar aqui e continuar, concordo com os
                                        Termos de Uso da Lummefy</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="row">
                <div class="col text-center mt-5">
                    <button type="button" class="mx-1 btn btn-outline-danger" v-if="progress > 0" @click="--progress">
                        Voltar
                    </button>
                    <button type="button" class="mx-1 btn btn-outline-primary" v-if="progress < 2"
                            @click="validateStep">
                        Próximo
                    </button>
                    <button type="button" class="mx-1 btn btn-outline-success" v-if="progress === 2" @click="save">
                        Confirmar
                    </button>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
import {ValidateForm} from "@/components/composables/cruds";
import {endLoading, startLoading} from "@/components/composables/spinners";
import http from '@/http'
import env from "@/env";
import store from "@/store";

export default {
    data() {
        return {
            form: {
                name: '',
                email: '',
                mobilePhone: '',
                cpfCnpj: '',
                postalCode: '',
                // incomeValue: '',
                birthDate: '',
                address: '',
                addressNumber: '',
                province: '',
                uf: '',
                city: '',
                complement: '',
                billingType: 'CREDIT_CARD',
                holderName: '',
                number: '',
                expiration: '',
                ccv: '',
                cycle: 'SEMIANNUALLY',
                plan: 2
            },
            progress: 0,
            finished: false,
            disabled: false
        }
    },
    methods: {
        async save() {
            startLoading('page', 'save');
            this.$store.commit('api/setSpinner', true);
            http.post('mentor', this.form)
                .then(async response => {
                    const token = this.$route.query.token;
                    if (token) {
                        const currentUser = store.state.auth.user;
                        await this.$store.commit('auth/setCurrentUser', {
                            ...currentUser,
                            plan: this.form.plan
                        });
                        await this.$router.push('/home');
                    } else {
                        this.$notifySuccess(response.data);
                        this.finished = true;
                    }
                })
                .catch(e => {
                    console.error('Checkout Mentor: ', e)
                    this.$notifyError(e);
                })
                .finally(() => {
                    endLoading('page', 'save');
                    this.$store.commit('api/setSpinner', false);
                })
        },

        async setAddress() {
            var cep = this.form.postalCode.replace(/\D/g, '');
            var validacep = /^[0-9]{8}$/;
            if (validacep.test(cep)) {
                http.get("https://viacep.com.br/ws/" + cep + "/json/?callback=?")
                    .then(response => {
                        let json = response.data.replace('?(', '').replace(');', '');
                        json = JSON.parse(json)


                        this.form.uf = json.uf;
                        this.form.city = json.localidade;
                        this.form.province = json.bairro;
                        this.form.address = json.logradouro;
                        this.form.complement = json.complemento;

                    })
            }
        },

        validateStep() {
            let required = {};
            if (this.progress === 1) {
                required = {
                    name: 'Nome Completo',
                    email: 'E-mail',
                    mobilePhone: 'Telefone',
                    cpfCnpj: 'CPF/CNPJ',
                    // birthDate: 'Data de Nascimento',
                    postalCode: 'CEP',
                    uf: 'UF',
                    city: 'Cidade',
                    address: 'Endereço',
                    province: 'Bairro',
                    addressNumber: 'sn',
                    // incomeValue: 'Renda Mensal',
                }
            } else if (this.progress === 2) {
                required = {
                    holderName: 'Nome do Titular do Cartão',
                    number: 'Número do Cartão',
                    expiration: 'Expiração',
                    ccv: 'Código de Segurança',
                }
            }

            if (!ValidateForm(this.$root, this.form, required)) {
                return;
            }

            ++this.progress;
        },

        getUser(token) {
            return http.post(env.api + 'mentors/me', {
                    token: token
                })
                .then(response => {
                    this.form.name = response.data.name;
                    this.form.email = response.data.email;
                    this.disabled = true;
                })
                .catch(error => {
                    console.error(error)
                    localStorage.removeItem('token');
                });
        }
    },

    mounted() {
        const token = this.$route.query.token;
        if (token) this.getUser(token);
    }
}
</script>

<style scoped>
.mt-5 {
    margin-top: 3rem;
}
</style>
